import { type BrowserAgent as BrowserAgentType } from '@newrelic/browser-agent/loaders/browser-agent';
import { useEffect, useState } from 'react';


export const agentOptions = {
  init: { privacy: { cookies_enabled: false }, ajax: { deny_list: ['bam.nr-data.net'] } },
  info: {
    beacon: 'bam.nr-data.net',
    errorBeacon: 'bam.nr-data.net',
    licenseKey: process.env.NEXT_PUBLIC_NEWRELIC_LICENSE_KEY,
    applicationID: process.env.NEXT_PUBLIC_NEWRELIC_APPLICATION_ID,
    sa: 1,
  },
  loader_config: {
    accountID: process.env.NEXT_PUBLIC_NEWRELIC_ACCOUNT_ID,
    trustKey: process.env.NEXT_PUBLIC_NEWRELIC_TRUST_KEY,
    agentID: process.env.NEXT_PUBLIC_NEWRELIC_AGENT_ID,
    licenseKey: process.env.NEXT_PUBLIC_NEWRELIC_LICENSE_KEY,
    applicationID: process.env.NEXT_PUBLIC_NEWRELIC_APPLICATION_ID,
  },
};

const useAgent = () => {
  const [agent, setAgent] = useState<BrowserAgentType | undefined>(undefined);

  const typeofWindow = typeof window;
  useEffect(() => {
    (async () => {
      const { BrowserAgent } = await import('@newrelic/browser-agent/loaders/browser-agent');
      if (typeof window !== 'undefined') {
        const newAgent = new BrowserAgent(agentOptions);
        setAgent(newAgent);
      }
    })();
  }, [typeofWindow]);

  return { agent };
};

export default useAgent;
